import React from 'react';

// Types
import { FieldRenderProps } from 'react-final-form';

export interface Props extends FieldRenderProps<any> {}

const Checkbox: React.FC<Props> = ({ id, input, meta, children, className }) => {
    return (
        <div className={`relative flex items-start ${className ?? ''}`}>
            <div className="flex h-5 items-center">
                <input
                    {...input}
                    id={id}
                    type="checkbox"
                    className={`form-checkbox h-5 w-5 cursor-pointer rounded-md ${
                        meta.error && meta.touched ? 'border-yellow-500' : 'border-gray-300'
                    } text-blue-500 shadow-sm !outline-0 !ring-0 !ring-transparent`}
                    data-cy={input.name}
                />
            </div>
            <div className="ml-4 text-sm">
                <label
                    className={`cursor-pointer ${
                        meta.error && meta.touched ? 'text-yellow-500' : 'text-gray-500'
                    }`}
                    htmlFor={id}
                >
                    {children}
                </label>
            </div>
        </div>
    );
};

export default Checkbox;
